import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import userActions from '../../state/user/actions'
import userSelectors from '../../state/user/selectors'

const QueryStaff = ({
  extStaffRef,
  done,
  loading,
  failure,
  getUser
}) => {
  useEffect(() => {
    if (!done && !loading && !failure && extStaffRef) {
      getUser()
    }
  }, [extStaffRef, done, loading, failure, getUser])

  return null
}

QueryStaff.propTypes = {
  getUser: PropTypes.func.isRequired,
  extStaffRef: PropTypes.string
}

QueryStaff.defaultProps = {
  extStaffRef: null
}

const mapStateToProps = state => ({
  loading: userSelectors.isLoadingUser(state),
  failure: userSelectors.isFailureLoadingUser(state),
  done: userSelectors.hasLoadedUser(state)
})

const mapDispatchToProps = dispatch => ({
  getUser () {
    dispatch(userActions.getUser())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(QueryStaff)
