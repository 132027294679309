/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import moment from 'moment'
import Swipe from 'react-easy-swipe'
import VehicleDataTableHeaderColumnValue from './VehicleDataTableHeaderColumnValue'
import VehicleDataColumn from './VehicleDataColumn'
import VehicleDetailsButton from './VehicleDetailsButton'
import ResponsiveDropdownMenu from '../ResponsiveDropdownMenu'
import closeMobile from '../../assets/closeMobile.svg'
import style from './VehicleDetails.scss'
import GAEvent from '../GAEvent'
import VehicleDetailsDot from './VehicleDetailsDot'
import ServicePlanningTable from './ServicePlanningTable'
import ReportsSchema from '../../util/ReportsSchema/ReportsSchema'
import { converttoTime } from '../../util/dataManipulation'

const controlValue = (value, col) => (col.showAsTime ? converttoTime(value) : value)

class VehicleDetails extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false,
      leftPanel: true,
      rightPanel: false
    }
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.onSwipeLeft = this.onSwipeLeft.bind(this)
    this.onSwipeRight = this.onSwipeRight.bind(this)
  }

  componentDidMount () {
    if (this.props.servicePlans.status) {
      this.setState({ leftPanel: true, rightPanel: false })
    }
  }

  onSwipeLeft () {
    this.setState({ leftPanel: !this.state.leftPanel, rightPanel: !this.state.rightPanel })
  }

  onSwipeRight () {
    this.setState({ leftPanel: !this.state.leftPanel, rightPanel: !this.state.rightPanel })
  }

  handleClickOutside () {
    this.setState({
      isOpen: false
    })
  }

  toggleDropdown () {
    if (this.props.servicePlans.status) {
      this.setState({ leftPanel: true, rightPanel: false })
    }
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render () {
    const {
      name, vehicle, previousPeriod,
      servicePlans, externalEquipmentReference, columns
    } = this.props
    const { rightPanel, leftPanel } = this.state
    return (
      <div className={`dropdown ${style.dropdown}`}>
        <VehicleDetailsButton
          name={name}
          notUsedVehicle={false}
          onClick={this.toggleDropdown}
          status={servicePlans.status}
        />
        {
          this.state.isOpen && (
            <ResponsiveDropdownMenu>
              <div className={`${style.dropdownMenu} row`}>
                <GAEvent category="click" action="user clicked show vehicle details" ReactGA={this.props.ReactGA} />
                <Swipe onSwipeLeft={this.onSwipeLeft} onSwipeRight={this.onSwipeRight} allowMouseEvents>
                  <div className={style.SwipeContainer}>
                    <div className="row">
                      <div className="col-xs-12" style={{ textAlign: 'center' }}>
                        {servicePlans.status && <VehicleDetailsDot right={rightPanel} left={leftPanel} /> }
                        <button type="button" onClick={this.toggleDropdown} className="close" style={{ float: 'right' }}>
                          <span aria-hidden="true"><img alt="close" height="28px" widht="28px" src={closeMobile} /></span>
                          <span className="sr-only">Close</span>
                        </button>
                      </div>
                    </div>
                    {servicePlans.status && leftPanel && (
                    <div>
                      <ServicePlanningTable
                        vehicleName={name}
                        servicePlans={servicePlans}
                        externalEquipmentReference={externalEquipmentReference}
                        ReactGA={this.props.ReactGA}
                      />
                    </div>
                    )}
                    { (!servicePlans.status || rightPanel) && (
                    <div>
                      <div className="row">
                        <div className="col-xs-12">
                          <h5 className="secret_text">{name}</h5>
                          { vehicle.info.periodStart && vehicle.info.periodEnd && <h6>{moment(vehicle.info.periodStart).format('lll')} - {moment(vehicle.info.periodStop).format('lll')}</h6> }
                          <hr />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <table className={`table ${style.table}`}>
                            <tbody>
                            {
                              columns.map(col => (
                                <tr key={col.titleKey}>
                                  <td>
                                    <VehicleDataTableHeaderColumnValue
                                      key={col.titleKey}
                                      name={col.titleKey}
                                      params={col.params}
                                    />
                                  </td>
                                  <td>
                                    <VehicleDataColumn
                                      vehicleName={name}
                                      parameter={col.titleKey}
                                      container={this}
                                      periodValue={vehicle.parameters[col.name] ? controlValue(vehicle.parameters[col.name].value, col) : null}
                                      referenceValue={col.showTrend ? vehicle.parameters[col.name].previous : null}
                                      delta={col.showTrend ? vehicle.parameters[col.name].delta : null}
                                      isGood={col.showTrend ? vehicle.parameters[col.name].isGood : null}
                                      previousPeriod={col.showTrend ? previousPeriod : null}
                                      unit={ReportsSchema.generateUnits(this.props.units, col)}
                                      ReactGA={this.props.ReactGA}
                                    />
                                  </td>
                                </tr>
                              ))
                            }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                </Swipe>
              </div>

            </ResponsiveDropdownMenu>
          )
        }
      </div>
    )
  }
}

VehicleDetails.propTypes = {
  servicePlans: PropTypes.shape({
    status: PropTypes.string.isRequired
  }).isRequired,
  externalEquipmentReference: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  vehicle: PropTypes.shape({
    info: PropTypes.any,
    parameters: PropTypes.shape().isRequired
  }).isRequired,
  units: PropTypes.shape().isRequired,
  previousPeriod: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  ReactGA: PropTypes.shape().isRequired
}

VehicleDetails.defaultProps = {
  previousPeriod: '/'
}

export default onClickOutside(VehicleDetails)
