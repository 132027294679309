import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { checkStatus, parseJSON, uuidv4 } from '../util/apiUtil'
import Authentication from './authentication'

const ssoFromFmp = (id, secret) => fetch(`${ENV_VAR_BFF_HOST}/api/v1/token/from-fmp/${id}/${secret}`, {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'text/plain; charset=utf-8',
    'X-Correlation-ID': uuidv4(),
    'X-Client': ENV_VAR_X_CLIENT
  }
})
  .then(checkStatus)
  .then(parseJSON)

const fetchLinkBaseQuery = fetchBaseQuery({
  baseUrl: `${ENV_VAR_BFF_HOST}/api/v1/token`,
  prepareHeaders: async (headers) => {
    const accessToken = await Authentication.getValidAccessToken()
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`)
    }
    headers.set('X-Correlation-ID', uuidv4())
    headers.set('X-Client', ENV_VAR_X_CLIENT)
    return headers
  }
})

export const fmpTokenApi = createApi({
  reducerPath: 'fmpTokenApi',
  baseQuery: fetchLinkBaseQuery,
  endpoints: (builder) => ({
    getFmpSSOLinkToTarget: builder.query({
      query: ({ target = '' }) => {
        const parts = target.split('/#/')
        const path = parts.length === 2 ? parts[1] : '/'
        return `/to-fmp?target=${encodeURI(path)}`
      }
    })
  })
})

export const { useLazyGetFmpSSOLinkToTargetQuery } = fmpTokenApi

export default {
  ssoFromFmp: (id, secret) => ssoFromFmp(id, secret)
}
