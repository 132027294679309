import authentication from '../../api/authentication'
import userApi from '../../api/user'
import SSO from '../../api/SSO'
import * as types from '../actionTypes'
import decode from '../../jwt/decoder'
import keycloak from '../../keycloak'

function requestLogin () {
  return {
    type: types.LOGIN_REQUEST
  }
}

function requestLoginFailure (error) {
  return {
    type: types.LOGIN_FAILURE,
    error: error.message
  }
}

function requestLoginBadCredentials (error) {
  return {
    type: types.LOGIN_BAD_CREDENTIALS,
    error
  }
}

function requestLoginLockedAccount (error) {
  return {
    type: types.LOGIN_LOCKED_ACCOUNT,
    error
  }
}

function requestLogout () {
  return {
    type: types.LOGOUT_REQUEST
  }
}

function requestLogoutSuccess () {
  return {
    type: types.LOGOUT_SUCCESS
  }
}

function badCredentialsHasBeenResolved () {
  return {
    type: types.BAD_CREDENTIALS_RESOLVED
  }
}

function requestLoginRequireSelectStaff (staffs) {
  return {
    type: types.LOGIN_SELECT_STAFF,
    staffs
  }
}

function loginCancelSelectStaff () {
  return {
    type: types.LOGIN_CANCEL_SELECT_STAFF
  }
}

const MONITORING_REPORT_ISSUER = 'monitoring-report'

function isRestricted (token) {
  return token.iss.indexOf(MONITORING_REPORT_ISSUER) >= 0
}

function mapTokenToUser (token) {
  return {
    extStaffRef: token.ExternalStaffReference
      ? token.ExternalStaffReference
      : token.externalStaffReference,
    extCustRef: token.ExternalCustomerReference
      ? token.ExternalCustomerReference
      : token.externalCustomerReference,
    permissions: {
      restricted: isRestricted(token),
      user: token.UserPermissions,
      functional: token.FunctionalPermissions
    }
  }
}

function foundExistingAuthentication (user) {
  return {
    type: types.FOUND_EXISTING_AUTHENTICATION,
    user
  }
}

function foundNoExistingAuthentication () {
  return {
    type: types.NO_EXISTING_AUTHENTICATION
  }
}

function expiredExistingAuthentication () {
  return {
    type: types.EXPIRED_EXISTING_AUTHENTICATION
  }
}

function requestLoginSuccess (user) {
  return {
    type: types.LOGIN_SUCCESS,
    user
  }
}

function isBadCredentials (error) {
  return error.response ? error.response.error_description.indexOf('InvalidCredentials') >= 0 : false
}

function isMultipleAccountsOnEmail (error) {
  return error.response ? error.response.error_description.indexOf('MultipleUsersExistForTheSameEmailAddress') >= 0 : false
}

function isLockedAccount (error) {
  return error.response ? error.response.error_description.indexOf('AccountIsLocked') >= 0 : false
}

function isExpiredSession (error) {
  return error.status === 404 || error.status === 401
}

function loadMagicToken (magicToken) {
  return authentication
    .exchangeMagicToken(magicToken)
    .then(result => ({ ...result, user: mapTokenToUser(decode(result.access_token)) }))
}

function loadFmpToken ({ kcToken, ssoId, ssoSecret }) {
  const tokenPromise = kcToken ? authentication.getFmpToken(kcToken) : SSO.ssoFromFmp(ssoId, ssoSecret)

  return tokenPromise.then(result => ({
    ...result,
    user: mapTokenToUser(decode(result.access_token))
  }))
}

export const cancelStaffSelection = () => (dispatch) => {
  dispatch(loginCancelSelectStaff())
}

export const breakOutOfRestricted = () => ({
  type: types.BREAK_OUT_OF_RESTRICTED
})

export const cancelBreakOutOfRestricted = () => ({
  type: types.CANCEL_BREAK_OUT_OF_RESTRICTED
})

export const login = creds => (dispatch) => {
  dispatch(requestLogin())
  return authentication.login(creds)
    .then(result => ({ ...result, user: decode(result.access_token) }))
    .then((result) => {
      dispatch(requestLoginSuccess(mapTokenToUser(result.user)))
    })
    .catch((error) => {
      if (isBadCredentials(error)) {
        dispatch(requestLoginBadCredentials(error))
      } else if (isMultipleAccountsOnEmail(error)) {
        return userApi.getUserByEmail(error.response.error_uri)
          .then((staffs) => dispatch(requestLoginRequireSelectStaff(staffs)))
          .catch(() => dispatch(requestLoginFailure(error)))
      } else if (isLockedAccount(error)) {
        dispatch(requestLoginLockedAccount(error))
      } else {
        dispatch(requestLoginFailure(error))
      }
    })
}

export const logout = () => (dispatch) => {
  dispatch(requestLogout())
  authentication.logout()
  dispatch(requestLogoutSuccess())
}

export const expiredExistingAuth = () => (dispatch) => {
  dispatch(expiredExistingAuthentication())
}

export const badCredentialsResolved = () => (dispatch) => {
  dispatch(badCredentialsHasBeenResolved())
}

/* eslint-disable no-console */
export const decideCredentialsToUse = (cachedCreds, ssoCreds, magicTokenCreds) => {
  console.log('[credentials] Deciding credentials to use')
  if (ssoCreds && (!ENV_VAR_MY_SCANIA || (keycloak || {}).authenticated)) {
    console.log('[credentials] Found existing authentication')
    authentication.storeTokens(ssoCreds)
    return foundExistingAuthentication(ssoCreds.user)
  } else if (magicTokenCreds && cachedCreds) {
    console.log('[credentials] Found cached and magic token')
    if (magicTokenCreds.user.extStaffRef !== cachedCreds.extStaffRef ||
      cachedCreds.permissions.restricted) {
      console.log('[credentials] Using magic token')
      authentication.cacheMagicToken(magicTokenCreds.access_token)
      return foundExistingAuthentication(magicTokenCreds.user)
    } else {
      console.log('[credentials] Using cached')
      return foundExistingAuthentication(cachedCreds)
    }
  } else if (magicTokenCreds) {
    console.log('[credentials] Using magic token')
    authentication.cacheMagicToken(magicTokenCreds.access_token)
    return foundExistingAuthentication(magicTokenCreds.user)
  } else if (cachedCreds) {
    console.log('[credentials] Using cached')
    return foundExistingAuthentication(cachedCreds)
  } else {
    console.log('[credentials] No credentials found')
    return foundNoExistingAuthentication()
  }
}

export const loadUser = ({
  magicToken,
  kcToken,
  ssoId,
  ssoSecret
} = {}) => (dispatch) => {
  const cachedToken = authentication.getAccessToken()

  let cachedUserPromise = Promise.resolve(null)
  let ssoUsePromise = Promise.resolve(null)
  let magicTokenUserPromise = Promise.resolve(null)

  if (cachedToken) {
    cachedUserPromise = Promise.resolve(mapTokenToUser(decode(cachedToken)))
  }

  if (kcToken || (ssoId && ssoSecret)) {
    ssoUsePromise = loadFmpToken({ kcToken, ssoId, ssoSecret })
  }

  if (magicToken) {
    magicTokenUserPromise = loadMagicToken(magicToken)
  }

  return Promise.all([cachedUserPromise, ssoUsePromise, magicTokenUserPromise])
    .then(result => dispatch(decideCredentialsToUse(result[0], result[1], result[2])))
    .catch((e) => {
      authentication.logout()
      if (isExpiredSession(e)) {
        return dispatch(expiredExistingAuthentication())
      }
      return dispatch(foundNoExistingAuthentication())
    })
}
