
import React from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import style from './ExportDownloadModal.scss'
import i18n from '../../util/localization'

const cancelText = 'Avbryt'

const ExportDownloadModal = ({ show, onSaveBlob, filename, onHide }) => (
  <Modal
    style={{ zIndex: 2000 }}
    dialogClassName={style.modal}
    keyboard
    onHide={onHide}
    bsSize="small"
    show={show}
  >
    <Modal.Body>
      <h3 className="text-center">{i18n.t('LaddaNer')}</h3>
      <button className="btn btn-block btn-success" onClick={() => { onSaveBlob(); onHide() }}><span className="glyphicon glyphicon-save" /> {filename}</button>
      <button className="btn btn-block btn-link" onClick={onHide}>{i18n.t(cancelText)}</button>
    </Modal.Body>
  </Modal>
)

ExportDownloadModal.propTypes = {
  show: PropTypes.bool.isRequired,
  filename: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onSaveBlob: PropTypes.func.isRequired
}

ExportDownloadModal.defaultProps = {
  filename: null
}

export default ExportDownloadModal
