import React from 'react'
const AuthenticationResolverLegacy = React.lazy(() => import('./AuthenticationResolverLegacy'))
const AuthenticationResolverKeycloak = React.lazy(() => import('./AuthenticationResolverKeycloak'))

const AuthenticationResolver = props => {
  return (
    ENV_VAR_MY_SCANIA
      ? <AuthenticationResolverKeycloak {...props } />
      : <AuthenticationResolverLegacy {...props } />
  )
}

export default AuthenticationResolver
