import moment from 'moment'
import i18n from '../localization'

export const createPeriod = (periodType: PeriodType, periodStart: string, periodEnd: string): string => {
  switch (periodType) {
    case ('week'): {
      const from = moment(periodStart)
      return `${i18n.t('HEADER_ReportType4')}: ${moment(from).format('L')}-${moment(from).add(7, 'days').format('L')}`
    }
    case ('month'): {
      const from = moment(periodStart)
      return `${i18n.t('HEADER_ReportType5')}: ${from.format('MMMM')}`
    }
    case ('year'): {
      const from = moment(periodStart)
      return `${i18n.t('HEADER_ReportType6')}: ${from.format('YYYY')}`
    }
    default: {
      const from = moment(periodStart).format('LLLL')
      const to = periodEnd ? moment(periodEnd).format('LLLL') : ''
      return `${i18n.t('HEADER_ReportType2')}: ${from} — ${to}`
    }
  }
}

export const createFilename = (periodType: PeriodType, periodStart: string, periodEnd: string, extension: string): string => {
  const separator = '_'
  const from = moment(periodStart)
  switch (periodType) {
    case ('week'): {
      return `${from.format('YYYYMMDD') + separator}${i18n.t('Vecka')}${extension}`
    }
    case ('month'): {
      return `${from.format('YYYYMM') + separator}${i18n.t('Månad')}${extension}`
    }
    case ('year'): {
      return `${from.format('YYYY') + separator}${i18n.t('År')}${extension}`
    }
    default: {
      const to = moment(periodEnd)
      return `${from.format('LLLL') + separator}${to.format('LLLL')}${extension}`
    }
  }
}

export const blobToBase64 = blob =>
  new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
