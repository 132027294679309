/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
import authentication from './authentication'
import { uuidv4 } from '../util/apiUtil'

const unauthorizedError = () => new Error('Unauthorized')
const forbiddenError = () => new Error('Forbidden')

const requestWasUnauthorized = response => response.status === 401
const requestWasForbidden = response => response.status === 403

const checkIfUnauthorized = (response) => {
  if (requestWasUnauthorized(response)) {
    throw (unauthorizedError())
  }
  return response
}

const fetchWithToken = (url, options = {}, refresh = false) => {
  const tokenPromise = options.accessToken ? Promise.resolve(options.accessToken) : authentication.getValidAccessToken(refresh)
  if (options.accessToken) {
    delete options.accessToken
  }

  return tokenPromise
    .then((accessToken) => {
      const headersWithAuthToken = {
        Authorization: `Bearer ${accessToken}`,
        'X-Correlation-ID': uuidv4(),
        ...options.headers,
        'X-Client': ENV_VAR_X_CLIENT
      }
      const optionsWithAuthToken = {
        ...options,
        headers: headersWithAuthToken
      }

      return fetch(url, optionsWithAuthToken)
    })
}

const fetchWithFreshToken = (url, options) => fetchWithToken(url, options, true)

export default (url, options) => fetchWithToken(url, options)
  .then((response) => {
    if (requestWasUnauthorized(response)) {
      return fetchWithFreshToken(url, options)
    }
    if (requestWasForbidden(response)) {
      throw (forbiddenError())
    }
    return response
  })
  .then(checkIfUnauthorized)
